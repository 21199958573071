import axios from "axios";

import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

export default function Game() { 

    const dispatch = useDispatch()

    const [url, setUrl] = useState("");

    const GetGame = async () => {
        const response = await axios.get("https://xpglive.shop/platform-api/game/live/24574", {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        })

        if(response.data.url != null) {
            setUrl(response.data.url)
        }
    }

    useEffect(() => {
        GetGame()
    }, [])

    useEffect(() => {
        
        
    }, [])

    const MaximizeFrame = () => {
        var doc = document.getElementById("mframe");

        if(doc != undefined) {
            doc.requestFullscreen()
        }
    }

    const Logout = () => {
        localStorage.removeItem("jwt")
        dispatch({
            type : "SET_USER",
            data : {
                user : null
            }
        })
    }

    return (
        <>
            <div className="app-game">
                <div className="full-bar">
                    <button onClick={MaximizeFrame}><i className="fas fa-expand"></i></button>
                    <button onClick={Logout} style={{marginLeft : "10px"}}><i className="fas fa-sign-out-alt"></i></button>
                </div>
                <iframe id="mframe" src={url} style={{width : "100%", height : "100%", border : "none", minHeight : "100vh"}}></iframe>
            </div>
        </>
    )
}