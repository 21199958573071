import "./Header.css";

import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Whisper, Popover } from 'rsuite';

import { useNavigate } from "react-router-dom";

export default function Header() {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const uxReducer = useSelector(state => state.uxReducer);
    const userx = useSelector(state => state.user);

    const ToggleLeftDrawer = () => {
        if(uxReducer.leftDrawerState) {
            dispatch({
                type : "SET_LEFT_DRAWER",
                data : {
                    state : false
                }
            })
        } else {
            dispatch({
                type : "SET_LEFT_DRAWER",
                data : {
                    state : true
                }
            })
        }
    }

    const Logout = () => {
        dispatch({
            type : "SET_USER",
            data : {
                user : null
            }
        })
    }

    return (
        <>
            <div className="app-header">
                <div className="left">
                    <i onClick={ToggleLeftDrawer} class="fas fa-bars"></i>
                    <img src="https://xpg.live/xpg/bg/XPG_logo_2020_ny.png" className="logo"></img>
                    <h3>ADMIN</h3>
                </div>
                <div className="right">
                    <div className="user-block">
                        <Whisper
                            trigger={"click"}
                            placement="bottomEnd"
                            controlId=""
                            speaker={
                                <Popover className="user-popover" style={{background : "white"}}>
                                    <ul className="popover-dropdown header-dropdown">
                                        <li><i className="fas fa-wallet"></i> {userx.user.credits} TND</li>
                                        <li onClick={() => navigate("/shop/profile")}><i class="fas fa-user"></i> Profile</li>
                                        <li onClick={Logout}><i class="fas fa-sign-out"></i> Logout</li>
                                    </ul>
                                </Popover>
                            }
                        >
                            <i class="fas fa-user-circle"></i>
                        </Whisper>  
                    </div>
                </div>
            </div>
        </>
    )
}