
import "./Header.css"

import {useState, useEffect, useRef} from "react";

import {useSelector, useDispatch} from "react-redux";

import {useNavigate} from "react-router-dom";

import { Tooltip, Whisper, Popover } from 'rsuite';

export default function Drawer(props) {
    
    const navigate = useNavigate();
    const {active} = props;

    const mRef = useRef();

    const uxReducer = useSelector(state => state.uxReducer);
    const dispatch = useDispatch();

    const [drawerState, setDrawerState] = useState("");
    const [debtsMenu, setDebtsMenu] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    const ToggleDrawerState = (state) => {
        if(drawerState == "") {
            if(!state) {
                setDebtsMenu(false);
                setDrawerState("opened");
                return ;
            }
            setDrawerState("init");
        } else {    
            if(state) {
                setDebtsMenu(false);
                setDrawerState("closed");
            } else {
                setDrawerState("opened");
            }
        }   
    }

    useEffect(() => {
        ToggleDrawerState(uxReducer.leftDrawerState)
    }, [uxReducer.leftDrawerState]);

    const ToggleDebtsMenu = () => {
        if(uxReducer.leftDrawerState) {
            setPopupVisible(true);
        } else {
            if(debtsMenu) {
                setDebtsMenu(false); 
            } else {
                setDebtsMenu(true);
            }
        }
    }

    return (
        <>
            <div className={"app-drawer "+drawerState}>
                <ul>
                    <li>
                        <Whisper
                            trigger={uxReducer.leftDrawerState ? "hover" : "none"}
                            placement={"rightEnd"}
                            controlId={`control-id-right`}
                            speaker={
                                <Tooltip>Transfer</Tooltip>
                            }
                        >
                            <div onClick={() => navigate("/shop/transfer")} className={"item-title"+(active == "transfer" ? " active" : "")}>
                                <i class="fas fa-exchange"></i>
                                <h2>Transfer</h2>
                            </div>
                        </Whisper>
                    </li>
                    <li>
                        <Whisper
                            trigger={uxReducer.leftDrawerState ? "hover" : "none"}
                            placement={"rightEnd"}
                            controlId={`control-id-right`}
                            speaker={
                                <Tooltip>Create Player</Tooltip>
                            }
                        >
                            <div onClick={() => navigate("/shop/create")} className={"item-title"+(active == "createpartner" ? " active" : "")}>
                                <i class="fas fa-user-plus"></i>
                                <h2>Create Player</h2>
                            </div>
                        </Whisper>
                    </li>
                    <li>
                        <Whisper
                            trigger={uxReducer.leftDrawerState ? "hover" : "none"}
                            placement={"rightEnd"}
                            controlId={`control-id-right`}
                            speaker={
                                <Tooltip>Players</Tooltip>
                            }
                        >
                            <div onClick={() => navigate("/shop")}className={"item-title"+(active == "partners" ? " active" : "")}>
                                <i class="fas fa-users"></i>
                                <h2>Players</h2>
                            </div>
                        </Whisper>
                    </li>
                    {/* <li>
                        <Whisper
                            trigger={uxReducer.leftDrawerState ? "hover" : "none"}
                            placement={"rightEnd"}
                            controlId={`control-id-right`}
                            speaker={
                                <Tooltip>Transactions</Tooltip>
                            }
                        >
                            <div onClick={() => navigate("/shop/transactions")} className={"item-title"+(active == "transactions" ? " active" : "")}>
                                <i class="fas fa-history"></i>
                                <h2>Transactions</h2>
                            </div>
                        </Whisper>
                    </li> */}
                </ul>
            </div>
            
        </>
    )
}