import './App.css';

import axios from "axios";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate
} from "react-router-dom";

import Login from './components/Login/Login';
import Game from "./components/Game/Game";
import Shop from "./components/Shop/Shop";
import Profile from './components/Shop/Profile';
import Create from "./components/Shop/Create";
import Transfer from './components/Shop/Transfer';
import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

function App() {

  const dispatch = useDispatch();

  const userx = useSelector(state => state.user);

  const [loaded, setLoaded] = useState(false);

  const CheckToken = async () => {
    try {
      const response = await axios.get("https://xpglive.shop/platform-api/player/refreshToken", {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("jwt")
        }
      })

      if (response.data.jwt != undefined) {
        localStorage.setItem("jwt", response.data.jwt)
        dispatch({
          type: "SET_USER",
          data: {
            user: {
              logged: true,
              role: 10,
              credits : response.data.credits
            }
          }
        })
        setLoaded(true);
      } else {
        localStorage.removeItem("jwt");
        setLoaded(true);
      }
    } catch(e) {
      localStorage.removeItem("jwt");
      setLoaded(true);
    }
  }

  useEffect(() => {
    if (localStorage.getItem("jwt") != undefined) {
      CheckToken()
    } else {
      setLoaded(true);
    }
  }, [])

  return (
    <div className="App">
      {
        loaded && (
          <Router>
            <Routes>
              <Route path="/" element={
                <>
                  {
                    userx.user == null ? (
                      <Login />
                    ) : (
                      userx.user.role == 10 ? (
                        <Navigate to="/game"></Navigate>
                      ) : (
                        <Navigate to="/shop"></Navigate>
                      )
                    )
                  }
                </>}></Route>
              <Route path="/game" element={
                <>
                  {
                    userx.user == null ? (
                      <Navigate to="/"></Navigate>
                    ) : (
                      <Game />
                    )
                  }
                </>
              }></Route>
              <Route path="/shop" element={
                <>
                  {
                    (userx.user == null || userx.role == 10) ? (
                      <Navigate to="/"></Navigate>
                    ) : (
                      <Shop />
                    )
                  }
                </>
              }></Route>
              <Route path="/shop/profile" element={
                <>
                  {
                    (userx.user == null || userx.role == 10) ? (
                      <Navigate to="/"></Navigate>
                    ) : (
                      <Profile />
                    )
                  }
                </>
              }></Route>
              <Route path="/shop/create" element={
                <>
                  {
                    (userx.user == null || userx.role == 10) ? (
                      <Navigate to="/"></Navigate>
                    ) : (
                      <Create />
                    )
                  }
                </>
              }></Route>
              <Route path="/shop/transfer" element={
                <>
                  {
                    (userx.user == null || userx.role == 10) ? (
                      <Navigate to="/"></Navigate>
                    ) : (
                      <Transfer />
                    )
                  }
                </>
              }></Route>
            </Routes>
          </Router>
        )
      }
    </div>
  );
}

export default App;
