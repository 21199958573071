const initialState = {
    leftDrawerState : true,
    debtInputId : "",
    debtValue : ""
}

export default function(state=initialState, action) {
    switch(action.type) {
        case "SET_LEFT_DRAWER":
            localStorage.setItem("drawer", action.data.state ? 1 : 0);
            return {
                ...state,
                leftDrawerState : action.data.state
            }
        break;
        case "SET_DEBT_INPUT":
            return {
                ...state,
                debtInputId : action.data.id
            }
        break;
        case "SET_DEBT_VALUE":
            return {
                ...state,
                debtValue : action.data.value
            }
        break;
        default:
            return state;
        break;
    }
}