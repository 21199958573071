import 'rsuite/dist/rsuite.min.css';

import axios from "axios";

import React, {useState, useEffect} from "react";

import { Table } from 'rsuite';

import { Link } from "react-router-dom";

import { Pagination } from 'rsuite';

import Header from "./Components/Header";
import Drawer from "./Components/Sidebar";

import { Panel, PanelGroup, Form } from 'rsuite';

export default function Agents() {

    const { Column, HeaderCell, Cell } = Table;

    const [pages, setPages] = useState(0);
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [username, setUsername] = useState("");

    const GetPlayers = async (usn) => {
        var response = await axios.get("https://xpglive.shop/platform-api-v2/cps/hierarchy/get-full", {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt")
            }
        });

        setData(response.data);
    }

    useEffect(() => {
        GetPlayers()
    }, [page, username]);
    
    return (
        <>
            <div className="default-app">
                <Header />

                <div className="app-content">
                    <Drawer active="partners" />
                    
                    <div className="main-content partner-content">

                        <div className="page-title">
                            <h1>Players</h1>
                            <div className="actions">

                            </div>
                        </div>

                        <div className="main-table">
                            <div className="table-content">
                                <Table
                                    data={data}
                                    autoHeight
                                >
                                    <Column width={60} align="center">
                                        <HeaderCell>Id</HeaderCell>
                                        <Cell dataKey="userId" />
                                    </Column>

                                    <Column flexGrow={1} minWidth={250}>
                                        <HeaderCell>Username</HeaderCell>
                                        {/* <Cell>{data => <Link to={"/agent/shops/"+data.id}>{data.Username}</Link>}</Cell> */}
                                        <Cell dataKey="username" />
                                    </Column>

                                    <Column flexGrow={1} minWidth={250}>
                                        <HeaderCell>Credits</HeaderCell>
                                        <Cell dataKey="credits" />
                                    </Column>
        

                                    {/* <Column width={200}>
                                        <HeaderCell>Actions</HeaderCell>
                                        <Cell dataKey="actions" />
                                    </Column> */}
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}