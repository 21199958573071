import {useState, useEffect} from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";

export default function Login() { 

    const dispatch = useDispatch()

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const SignIn = async () => {
        setError("");
        setLoading(true)

        // https://xpglive.shop

        var response = await axios.post("https://xpglive.shop/platform-api/auth/login", "login="+username+"&password="+password+"&platform=desktop&device=Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36", {
            headers : {
                "content-type" : "application/x-www-form-urlencoded; charset=UTF-8"
            }
        })

        setLoading(false);

        if(response.data.errors != undefined && response.data.errors.length > 0) {
            setError(response.data.errors[0].message)
        } else {
            
            localStorage.setItem("jwt", response.data.jwt);

            dispatch({
                type : "SET_USER",
                data : {
                    user : {
                        logged : true,
                        role : response.data.role,
                        credits : response.data.credits
                    }
                }
            })

            if(response.data.role == 10) {
                navigate("/game");
            } else {
                navigate("/shop");
            }
        }
    }

    return (
        <>
            <div className="app-login">

                <div className="login-box-overlay"></div>
                
                <div className="login-box">

                    <h2><img src="https://xpg.live/xpg/bg/XPG_logo_2020_ny.png"></img></h2>

                    {
                        error != "" && (
                            <div className="error">
                                {error}
                            </div>
                        )
                    }
                    
                    <div className="input-box">
                        <i className="fas fa-user"></i>
                        <input type="text" placeholder="Username" onChange={e => setUsername(e.target.value)} />
                    </div>

                    <div className="input-box">
                        <i className="fas fa-key"></i>
                        <input type="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                    </div>

                    <button className={loading ? "disabled_button" : ""} disabled={loading} onClick={SignIn}>Sign In</button>

                </div>

            </div>
        </>
    )
}