

import React, {useState, useEffect} from "react";

import { Table, Button, Input, InputGroup, SelectPicker } from 'rsuite';
import Message from 'rsuite/Message';

import Header from "./Components/Header";
import Drawer from "./Components/Sidebar";

import axios from "axios";

export default function CreateShop() {

    const { Column, HeaderCell, Cell } = Table;

    const [agent, setAgent] = useState("");
    const [data, setData] = useState([]);

    const [form, setForm] = useState({
        username : "",
        password : ""
    });

    const [disabled, setDisabled] = useState(false);

    const [message, setMessage] = useState({
      message : "",
      type : ""  
    })

    const CreatePartner = async () => {
        setDisabled(true);

        var response = await axios.post("https://xpglive.shop/platform-api-v2/user/create-user", "username="+form.username+"&password="+form.password+"&name=abc&surname=abc", {
            headers : {
                "Authorization" : "Bearer "+localStorage.getItem("jwt"),
                "content-type" : "application/x-www-form-urlencoded; charset=UTF-8"
            }
        });

        if(response.data.success == true) {
            setMessage({
                type : "success",
                message : "Partner Created Successfully"
            })
            setDisabled(false);
            setForm({
                username : "",
                password : ""
            })
        } else {
            setDisabled(false);
            setMessage({
                type : "error",
                message : "Couldn't Create Partner"
            })
        }
    }


    return (
        <>
            <div className="default-app">
                <Header />

                <div className="app-content">
                    <Drawer active="createpartner" />
                    
                    <div className="main-content partner-content">

                        <div className="page-title">
                            <h1>Create Player</h1>
                        </div>

                        <div className="create-form">
                            {
                                message.message != "" && (
                                    <div className="alert">
                                        <Message showIcon type={message.type}>{message.message}</Message>
                                    </div>
                                )
                            }
                            
                            <div className="input-group">
                                <label>Username</label>
                                <Input onChange={value => setForm({...form, username : value})} value={form.username} placeholder="Username"></Input>
                            </div>
                            <div className="input-group">
                                <label>Password</label>
                                <Input type="password" onChange={value => setForm({...form, password : value})} value={form.password} placeholder="Password"></Input>
                            </div>
                            <div className="action-group">
                                <Button disabled={disabled} onClick={CreatePartner} color="primary">Create Player</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}