

import React, {useState, useEffect} from "react";

import { Table, Button, Input, InputGroup, SelectPicker } from 'rsuite';
import Message from 'rsuite/Message';

import Header from "./Components/Header";
import Drawer from "./Components/Sidebar";

import axios from "axios";

export default function Profile() {

    const { Column, HeaderCell, Cell } = Table;

    const [agent, setAgent] = useState("");
    const [data, setData] = useState([]);

    const [form, setForm] = useState({
        old_password : "",
        password : "",
        re_password : ""
    });

    const [disabled, setDisabled] = useState(false);

    const [message, setMessage] = useState({
      message : "",
      type : ""  
    })

    const EditPassword = async () => {
        if(form.password != "") {
            if(form.password != form.re_password) {
                setMessage({
                    message : "Mismatch passwords",
                    type : "error"
                })
                return ;
            }

            var response = await axios.post("https://xpglive.shop/platform-api-v2/auth/update-password", "oldPassword="+form.old_password+"&newPassword="+form.password, {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            });

            if(response.data.error == null) {
                setMessage({
                    type : "success",
                    message : "Profile updated"
                })
            } else {{
                setMessage({
                    type : "error",
                    message : "Error while updating profile"
                })
            }}
        } else {
            setMessage({
                message : "Empty password",
                type : "error"
            })
        }
    }


    return (
        <>
            <div className="default-app">
                <Header />

                <div className="app-content">
                    <Drawer active="shops" />
                    
                    <div className="main-content partner-content">

                        <div className="page-title">
                            <h1>User Profile</h1>
                        </div>

                        <div className="create-form">
                            {
                                message.message != "" && (
                                    <div className="alert">
                                        <Message showIcon type={message.type}>{message.message}</Message>
                                    </div>
                                )
                            }
                            
                            <div className="input-group">
                                <label>Old Password</label>
                                <Input type="password" onChange={value => setForm({...form, old_password : value})} value={form.old_password} placeholder="Old Password"></Input>
                            </div>
                            <div className="input-group">
                                <label>New Password</label>
                                <Input type="password" onChange={value => setForm({...form, password : value})} value={form.password} placeholder="New Password"></Input>
                            </div>
                            <div className="input-group">
                                <label>Repeat Password</label>
                                <Input type="password" onChange={value => setForm({...form, re_password : value})} value={form.re_password} placeholder="Repeat Password"></Input>
                            </div>

                            <div className="action-group">
                                <Button disabled={disabled} onClick={EditPassword} color="primary">Edit Password</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}