const initialState = {
    user : null,
    logged : false
}

export default function UserReducer(state=initialState, action) {
    switch(action.type) {
        case "SET_USER":
            return {
                ...state,
                user : action.data.user
            }
        break;
        default:
            return state;
        break;
    }
}