

import React, {useState, useEffect} from "react";

import { Table, Button, Input, InputGroup, SelectPicker, Checkbox } from 'rsuite';

import axios from "axios";

import Message from 'rsuite/Message';

import Header from "./Components/Header";
import Drawer from "./Components/Sidebar";

import {useSelector, useDispatch} from "react-redux";

export default function Transfer() {

    const { Column, HeaderCell, Cell } = Table;

    const userx = useSelector(state => state.userReducer);

    const [method, setMethod] = useState("deposit");
    const [data, setData] = useState([]);
    const [message, setMessage] = useState({
        message : "",
        type : ""
    });
    const [userId, setUserId] = useState(-1);
    const [amount, setAmount] = useState("");
    const [paid, setPaid] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [sourceUserId, setSourceUserId] = useState(null);
    const [targetUserId, setTargetUserId] = useState(null);

    const GetSubs = async () => {
        try {
            var response = await axios.get("https://xpglive.shop/platform-api-v2/cps/hierarchy/get-full-ext", {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            });

            setData(response.data)
        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {
        GetSubs();
    }, []);

    const MakeTransfer = async () => {
        try {

            setDisabled(true);
            
            var form = {
                sourceUserId : sourceUserId,
                targetUserId : targetUserId,
                amount : amount
            };

            var response = await axios.post("https://xpglive.shop/platform-api-v2/balance/transfer", "sourceUserId="+sourceUserId+"&targetUserId="+targetUserId+"&amount="+(amount*100)+"&note=", {
                headers : {
                    "Authorization" : "Bearer "+localStorage.getItem("jwt")
                }
            });

            setDisabled(false);

            if(response.data.error == null) {
                setSourceUserId(null);
                setTargetUserId(null);
                setAmount("");
                setMessage({
                    message : "Transaction made successfully",
                    type : "success"
                })
            } else {
                setMessage({
                    message : response.data.error,
                    type : "error"
                })
            }
        } catch(e) {
            setDisabled(false);
            console.log(e);
            setMessage({
                message : e.response.data.error,
                type : "error"
            })
        }
    }

    const TogglePaid = () => {
        if(paid) {
            setPaid(0);
        } else {
            setPaid(1);
        }
    }

    return (
        <>
            <div className="default-app">
                <Header />

                <div className="app-content">
                    <Drawer active="transfer" />
                    
                    <div className="main-content partner-content">
                        <div className="page-title">
                            <h1>Make Transfer</h1>
                        </div>

                        <div className="create-form">

                            <div className="message-alert">
                                {
                                    message.message != "" && (
                                        <div className="alert">
                                            <Message showIcon type={message.type}>{message.message}</Message>
                                        </div>
                                    )
                                }
                            </div>
                            
                            <div className="input-row">
                                <div className="input-group">
                                    <label>From User</label>
                                    <SelectPicker onChange={value => setSourceUserId(value)} data={data} style={{ width: "100%" }} />
                                </div>
                                <div className="input-group">
                                    <label>To User</label>
                                    <SelectPicker onChange={value => setTargetUserId(value)} data={data} style={{ width: "100%" }} />
                                </div>
                            </div>
                            <div className="input-group">
                                <label>Amount</label>
                                <Input onChange={value => setAmount(value)} value={amount} placeholder="Amount"></Input>
                            </div>
                            <div className="action-group">
                                <Button disabled={disabled} onClick={MakeTransfer} color="primary">Transfer</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}